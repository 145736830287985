@import './_variables.css';
@import './_finalize-variables.css';

@media screen {
  .gallery {
    position: relative;
    width: 100vw;
    height: 50vh;
    margin-bottom: 25px;

    &-image {
      display: none;
      user-select: none;

      &.is-active {
        display: block;
        width: auto;
        height: 75%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 768px) {
          &.mod-front,
          &.mod-knob,
          &.mod-barrelEnd {
            width: 100vw;
            max-width: 440px;
            height: unset;
          }

          &.mod-knob {
            transform: translate(-57%, -50%);
          }

          &.mod-front {
            /* Rotate to "reduce" the horizontal size of the image so it doesn't overlap with the arrows. */
            transform: translate(-50%, -50%) rotate(-20deg);
          }
        }
      }
    }

    &-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      transition: opacity 0.1s;
      padding: 20px;
      box-sizing: content-box;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &.mod-prev {
        left: 0;
      }

      &.mod-next {
        right: 0;
      }
    }

    &-bullets {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &-bullet {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      opacity: 0.2;
      transition: opacity 0.1s;
      cursor: default;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.01);
      user-select: none;

      &:after {
        content: '';
        color: var(--color-black);
        background: var(--color-black);
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }

      &.is-active {
        opacity: 1;
      }
    }

    @media (min-width: 769px) {
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      &-arrow,
      &-bullets {
        display: none;
      }

      &-image {
        &:not(.is-default) {
          display: block;
          width: 30vh;
          max-width: 40%;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(20%, -110%);

          &.mod-back {
            transform: translate(20%, 0%);
          }
        }

        &.is-default {
          display: block;
          width: 50vh;
          height: auto;
          max-width: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-90%, -50%);
        }

        &.mod-front {
          max-width: 1440px;
          width: var(--preview-image-max-width);
          /* The translations are applied to fine-tune the position of the images in a kind of grid, so no way to avoid magic numbers. */
          transform: translate(-50%, -90%);
        }

        &.mod-barrelEnd {
          max-width: 560px;
          width: calc((var(--preview-image-max-width) - 2%) * 0.4);
          transform: translate(-119%, -8%);
        }

        &.mod-knob {
          max-width: 840px;
          width: calc((var(--preview-image-max-width) - 2%) * 0.6);
          transform: translate(-21%, -5%);
        }
      }
    }
  }

  .gallery.has-single-preview .gallery-image {
    width: 100%;
    max-width: none;
    transform: translate(-50%, -50%);
  }
}
